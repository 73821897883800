import InCyberFooter from '@/components/incyber-footer';
import InCyberHeader from '@/components/incyber-header';
import HeroBanner from '@/components/page-hero-banner';
import HeadC from '@/ctf-components/headc/headc';
import NavigationButton from '@/ctf-components/navigationButton';
import Section from '@/ctf-components/section/section';
import { fetchHomePageIncyberData, useHomeIncyberData } from '@/hooks';
import { fetchInCyberFooterData } from '@/hooks/useInCyberFooter';
import { fetchInCyberHeaderNavigation } from '@/hooks/useInCyberNavigation';
import { SectionIC } from '@/lib/interface-types';
import { QueryClient, dehydrate } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import uuid from 'react-uuid';

const HomeCyber = () => {
  const { locale, query } = useRouter();
  const { data, isLoading } = useHomeIncyberData(locale);

  const {
    sectionsCollection,
    pageName,
    titleDescription,
    containerForCustomButton,
    slug,
    seo
  } = data?.homePageCollection?.items[0] || {};

  if (isLoading) return <div>Loading</div>;

  return (
    <>
      <HeadC seo={seo} />
      <InCyberHeader />
      <main className="container mx-auto">
        <HeroBanner title={pageName} richText={titleDescription} coloredTitle />
        <div>
          {sectionsCollection?.items &&
            sectionsCollection.items.map((section: SectionIC) => {
              return <Section slug={slug} key={uuid()} section={section} />;
            })}
        </div>
        <div className="CustomButton mt-20">
          <NavigationButton data={containerForCustomButton} />
        </div>
      </main>
      <InCyberFooter />
    </>
  );
};

export async function getStaticProps({ locale }: { locale: string }) {
  const queryClient = new QueryClient();
  await queryClient.prefetchQuery({
    queryKey: ['homecyber', locale],
    queryFn: () => fetchHomePageIncyberData(locale)
  });
  await queryClient.prefetchQuery({
    queryKey: ['incyberfooter', locale],
    queryFn: () => fetchInCyberFooterData(locale)
  });
  await queryClient.prefetchQuery({
    queryKey: ['incyberheader', locale],
    queryFn: () => fetchInCyberHeaderNavigation(locale)
  });

  return {
    props: {
      dehydratedState: dehydrate(queryClient)
    },
    revalidate: 10
  };
}

export default HomeCyber;
